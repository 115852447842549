/*
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */

var testCircle = $('#test-circle');
if (testCircle.length && typeof circliful !== 'undefined') {
    testCircle.circliful({
        animation: 1,
        animationStep: 5,
        foregroundBorderWidth: 7,
        backgroundBorderWidth: 7,
        textSize: 28,
        textStyle: 'font-size: 12px;',
        textColor: '#666',
        multiPercentage: 1,
        showPercent: 1,
        percentages: [
            {'percent': 10, 'color': '#3180B8', 'title': 'ESG'},
            {'percent': 30, 'color': '#4ADBEA', 'title': 'Sustainable Revenue'},
            {'percent': 50, 'color': '#49EBA8', 'title': 'AMI'},
        ],
        multiPercentageLegend: 1,
        replacePercentageByText: '',
        backgroundColor: '#eee',
    })
}


