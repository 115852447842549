
var colorTheme = [];
    colorTheme['color-theme-environment-and-ecology'] = '#02ca2d';
    colorTheme['color-theme-education-and-equality'] = '#f2d600';
    colorTheme['color-theme-enterprise-and-infrastructure'] = '#2cd2fc';
    colorTheme['color-theme-governance-and-partnerships'] = '#ed4e1d';
    colorTheme['color-theme-other'] = '#666666';
    colorTheme['color-asset-cash'] = '#0c6d6a';
    colorTheme['color-asset-equity'] = '#1ab0aa';
    colorTheme['color-asset-alternatives'] = '#138f8b';
    colorTheme['color-asset-fixed-income'] = '#23dcd5';
    colorTheme['color-asset-total'] = '#000000';
    colorTheme['color-asset-all'] = '#666666';
    colorTheme['color-impact-benchmark'] = '#f7cddf';
    colorTheme['color-impact-tribe'] = '#f53385';
    
jQuery(document).ready(function()
{
    jQuery('.asset-filter-cta').click(function()
    {
        jQuery(this).parent().parent('ul.asset-allocation-fitler').find('.asset-filter-cta').removeClass('asset-filter-cta-active');
        jQuery(this).addClass('asset-filter-cta-active');
    });

    jQuery('form').attr('autocomplete', 'off');
    jQuery('form').find('input').attr('autocomplete', 'off');

    /* Open Links In Blank Windows */
    jQuery(function(){
        jQuery('a[href$=".pdf"]').attr('target', '_blank');
        jQuery('a[href$=".zip"]').attr('target','_blank');
        jQuery('a[href$=".rar"]').attr('target','_blank');
        jQuery('a[href$=".txt"]').attr('target','_blank');
        jQuery('a[href$=".doc"]').attr('target','_blank');
        jQuery('a[href$=".docx"]').attr('target','_blank');
        jQuery('a[href$=".xls"]').attr('target','_blank');
        jQuery('a[href$=".xlsx"]').attr('target','_blank');
        jQuery("a[href^='http:']").not("[href*='"+ location.hostname +"']").attr('target','_blank');
        jQuery("a[href^='https:']").not("[href*='"+ location.hostname +"']").attr('target','_blank');
    });
    
    
    
    
    
    
    
    
    
    
    
    jQuery(".mobile-menu-button").unbind('click');
    jQuery(".mobile-menu-button").click(function()
    {
        if(!jQuery(this).hasClass('open'))
        {
            jQuery(".mobile-menu-button").addClass('open');
            jQuery(".mobile-menu-header ul.nav").fadeIn(100);
        }
        else
        {
            closeMobileMenu();
        }
    });
    function closeMobileMenu()
    {
        jQuery(".mobile-menu-header ul.nav").hide();
        jQuery(".mobile-menu-button").removeClass("open");
    }
    
    jQuery(window).resize(function()
    {
        if(jQuery(document).width() > 767)
        {
                jQuery(".mobile-menu-nav").hide();
                closeMobileMenu();	
        }
    });
        
        
        
        
        
        
        
    
    
    
});